import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class VehiculoService {
  async getVehiculosAll() {
    const vehiculos = await fetchWrapper.get(`${ruta}/vehiculos`);
    return vehiculos;
  }

  async sendVehiculoNew(datos) {
    const newVehiculo = await fetchWrapper.post(`${ruta}/vehiculos`, datos);
    return newVehiculo;
  }

  async updateVehiculo(datos) {
    const updateVehiculo = await fetchWrapper.put(
      `${ruta}/vehiculos/` + datos.id,
      datos
    );
    return updateVehiculo;
  }

  async deleteVehiculo(id) {
    return await fetchWrapper.delete(`${ruta}/vehiculos/${id}`);
  }
}
